<template>
    <div class="com-three-model">
        <muz-threes :three-config="threeConfig"></muz-threes>
    </div>
    
</template>

<script>
import MuzThrees from 'muz-threes';
export default {
    components: {
        MuzThrees,
    },
    data: () => ({
        threeConfig: {
            /** 基础配置（单位：米） */
            config: {
                position: {x: -20, y: 10, z: -20},
                lookAt: {x: 0, y: 0, z: 0},
                near: 0.1,
                
                grid: {},
                axes: {},
                
                orbit: {
                    mapControl: false,
                    /* 以下原生属性 */
                    target: {x: 0, y: 0, z: 0},
                    autoRotate: true,
                },
            },
            /** 各种物体 */
            object3Ds: [
                // {
                //     url: './models/training-groung/0520.gltf',
                //     type: 'gltf',
                //     scale: {x: 1, y: 1, z: 1},
                //     position: {x: 0, y: 0, z: 0},
                // },
                {
                    url: './models/tree_a/tree_a.gltf',
                    type: 'gltf',
                    scale: {x: 1, y: 1, z: 1},
                    position: {x: 0, y: 0, z: 0},
                },
                {
                    url: './models/mttu/MTTU-125CF-coldface.stl',
                    type: 'stl',
                    scale: {x: 100, y: 100, z: 100},
                    position: {x: 0, y: 0, z: 0},
                },
            ],
            bubbles: [
                {
                    list: [
                        {text: "这里", position: {x: 0, y: 0, z: 0}},
                    ],
                },
            ],
            // panoramas: [
            //     {
            //         map: './models/earth/earth_8k.jpg',
            //     }
            // ],
        }
    }),

    }
</script>

<style>
    .com-three-model{top: 0; right: 0; width: 33%; height: 20vh; /* position: absolute; */border: 1px solid rgba(69,192,251,0.2); z-index: 1;}
</style>
